
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>营销活动管理</h3>
      <p>
        基于MAP营销自动化平台，微心动可以帮助您管理和实现覆盖大规模人群和跨渠道的营销活动，在CDP客户数据平台和Ad
        Hub智能广告平台的支持下，营销活动效果将会更精准，提供更好的用户个性化体验。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="activity">
      <h6>跨渠道营销，支持丰富的活动类型</h6>
      <img src="../../assets/images/activity_bj.png" alt="" />
    </div>
    <div class="activity_yx">
      <h6>微心动营销活动中台</h6>
      <p class="title">一体化全渠道的营销活动管理中台，覆盖营销活动全链路</p>
      <div>
        <p><img src="../../assets/images/activity11.png" />营销自动化平台</p>
        <p><img src="../../assets/images/activity22.png" />客户数据管理平台</p>
        <p><img src="../../assets/images/activity33.png" />会员管理平台</p>
        <p><img src="../../assets/images/activity44.png" />内容管理平台</p>
        <p>
          <img src="../../assets/images/activity55.png" />Ad Hub智能广告平台
        </p>
      </div>
    </div>
    <div class="content">
      <div>
        <h6>精准的活动人群定位和分层</h6>
        <p>
          精准客户画像: 通过属性、来源、事件、活跃度等指标形成精准用户画像<br />
          智能化标签体系: 体系化的标签管理，对用户进行多维度的聚类分层<br />
          全渠道客户整合: 对分散在多渠道的客户进行整合，营销活动精准触达<br />
          AI智能推荐系统: 为多场景营销活动提供精准的商品推荐和人群匹配
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/activity01.png" />
    </div>
    <div class="content">
      <img src="../../assets/images/activity02.png" />
      <div>
        <h6>自动化营销活动</h6>
        <p>
          营销活动自动化运行，减少重复性工作，降低营销成本，提高活动执行效率<br />
          全新自动流3.0引擎，支持所有营销场景的自动化设置，提高决策准确性<br />
          可视化营销活动编辑器，并提供丰富的营销模板，复杂营销活动轻松运营<br />
          支持大规模的营销活动A/B测试，选择最优营销活动方案，确保活动ROI率
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="content">
      <div>
        <h6>智能化营销活动</h6>
        <p>
          AI智能圈群和聚类分析，有效提升营销人群精准度和活动效果<br />
          NBA（Next Best Action）智能营销决策引擎，有效提升营销RFM<br />
          智能推荐系统，为多场景营销活动提供精准的商品推荐和人群匹配<br />
          智能化广告投放管理，实时人群决策引擎提升营销活动广告投放ROI
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/activity03.png" />
    </div>
    <div class="content">
      <img src="../../assets/images/activity04.png" />
      <div>
        <h6>企业级的营销活动产品</h6>
        <p>
          完善的营销活动统计、分析和策略迭代<br />
          丰富的全行业营销活动项目实施经验<br />
          提供营销活动标签解决方案和顾问服务<br />
          专业投放团队提供广告咨询和运营服务
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization04.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.activity {
  text-align: center;
  padding: 1.36rem 0 1rem;
  h6 {
    font-size: 0.28rem;
    padding-bottom: 0.58rem;
  }
  img {
    width: 14.4rem;
    height: 5.16rem;
  }
}
.activity_yx {
  text-align: center;
  h6 {
    font-size: 0.28rem;
    padding-bottom: 0.2rem;
  }
  .title {
    font-size: 0.18rem;
    padding-bottom: 0.48rem;
  }
  div {
    padding: 0 2rem 1rem;
    display: flex;
    justify-content: space-between;
    p {
      width: 2.1rem;
      font-size: 0.22rem;
      img {
        width: 0.8rem;
        height: 0.8rem;
        display: block;
        margin: 0 auto 0.26rem;
      }
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 6.32rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content:nth-child(2n) {
  background-color: #fffff5;
  padding: 0.68rem 0;
  div {
    width: 6.32rem;
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
