var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"heading"},[_c('h3',[_vm._v("营销活动管理")]),_c('p',[_vm._v(" 基于MAP营销自动化平台，微心动可以帮助您管理和实现覆盖大规模人群和跨渠道的营销活动，在CDP客户数据平台和Ad Hub智能广告平台的支持下，营销活动效果将会更精准，提供更好的用户个性化体验。 ")]),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("申请试用")])]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',[_c('h6',[_vm._v("精准的活动人群定位和分层")]),_vm._m(2),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])]),_c('img',{attrs:{"src":require("../../assets/images/activity01.png")}})]),_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/activity02.png")}}),_c('div',[_c('h6',[_vm._v("自动化营销活动")]),_vm._m(3),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])])]),_c('div',{staticClass:"content"},[_c('div',[_c('h6',[_vm._v("智能化营销活动")]),_vm._m(4),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])]),_c('img',{attrs:{"src":require("../../assets/images/activity03.png")}})]),_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/activity04.png")}}),_c('div',[_c('h6',[_vm._v("企业级的营销活动产品")]),_vm._m(5),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity"},[_c('h6',[_vm._v("跨渠道营销，支持丰富的活动类型")]),_c('img',{attrs:{"src":require("../../assets/images/activity_bj.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity_yx"},[_c('h6',[_vm._v("微心动营销活动中台")]),_c('p',{staticClass:"title"},[_vm._v("一体化全渠道的营销活动管理中台，覆盖营销活动全链路")]),_c('div',[_c('p',[_c('img',{attrs:{"src":require("../../assets/images/activity11.png")}}),_vm._v("营销自动化平台")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/images/activity22.png")}}),_vm._v("客户数据管理平台")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/images/activity33.png")}}),_vm._v("会员管理平台")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/images/activity44.png")}}),_vm._v("内容管理平台")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/images/activity55.png")}}),_vm._v("Ad Hub智能广告平台 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 精准客户画像: 通过属性、来源、事件、活跃度等指标形成精准用户画像"),_c('br'),_vm._v(" 智能化标签体系: 体系化的标签管理，对用户进行多维度的聚类分层"),_c('br'),_vm._v(" 全渠道客户整合: 对分散在多渠道的客户进行整合，营销活动精准触达"),_c('br'),_vm._v(" AI智能推荐系统: 为多场景营销活动提供精准的商品推荐和人群匹配 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 营销活动自动化运行，减少重复性工作，降低营销成本，提高活动执行效率"),_c('br'),_vm._v(" 全新自动流3.0引擎，支持所有营销场景的自动化设置，提高决策准确性"),_c('br'),_vm._v(" 可视化营销活动编辑器，并提供丰富的营销模板，复杂营销活动轻松运营"),_c('br'),_vm._v(" 支持大规模的营销活动A/B测试，选择最优营销活动方案，确保活动ROI率 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" AI智能圈群和聚类分析，有效提升营销人群精准度和活动效果"),_c('br'),_vm._v(" NBA（Next Best Action）智能营销决策引擎，有效提升营销RFM"),_c('br'),_vm._v(" 智能推荐系统，为多场景营销活动提供精准的商品推荐和人群匹配"),_c('br'),_vm._v(" 智能化广告投放管理，实时人群决策引擎提升营销活动广告投放ROI ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 完善的营销活动统计、分析和策略迭代"),_c('br'),_vm._v(" 丰富的全行业营销活动项目实施经验"),_c('br'),_vm._v(" 提供营销活动标签解决方案和顾问服务"),_c('br'),_vm._v(" 专业投放团队提供广告咨询和运营服务 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('h6',[_vm._v("500＋企业的共同选择")]),_c('p',[_vm._v("千人千面的策略布置")]),_c('img',{attrs:{"src":require("../../assets/images/hzqy.png"),"alt":""}})])
}]

export { render, staticRenderFns }